/* eslint-disable no-undef */
import { Fragment, Component } from 'preact';
import { route } from 'preact-router';

import { Button, Prompt, Screen } from './components';
import axios from 'axios';
import { ReviewConfigContext } from './ReviewConfigContext';

const startReview = (reviewFeedbackId, serviceName) =>
  axios.post(`/review/status/${reviewFeedbackId}`, {
    status: 'started',
    service_name: serviceName,
  });

export default class SubmitFeedback extends Component {
  state = { value: '' };

  onSubmit = () => {
    const { reviewId, reviewFeedbackId } = this.props;
    const { value } = this.state;
    axios
      .post(`/review/status/${reviewFeedbackId}`, {
        status: 'completed',
        content: value,
      })
      .then(() => route(`/${reviewId}/thanks`));
  };

  onPublicReview = () => {
    const { reviewFeedbackId } = this.props;
    route(`/${reviewFeedbackId}/prompt/0`);
  };

  onInput = e => {
    const { value } = e.target;
    this.setState({ value });
  };

  componentDidMount() {
    const { reviewFeedbackId } = this.props;
    startReview(reviewFeedbackId, 'direct');
  }

  render() {
    const { rating } = this.props;
    const { value } = this.state;

    const isHappy = rating === '3' || rating === '4';

    return (
      <ReviewConfigContext.Consumer>
        {({ config }) => {
          const shouldAskForFeedback =
            isHappy &&
            config.review_mode !== 'FEEDBACK' &&
            config.reviewSites &&
            config.reviewSites.length > 0;

          return (
            <Screen id="feedback">
              <Prompt>
                {isHappy
                  ? "Is there anything you'd like to share?"
                  : 'How could your experience have been better?'}
              </Prompt>
              <textarea
                id="feedback-input"
                rows="4"
                cols="50"
                type="textarea"
                value={value}
                onInput={this.onInput}
                style={{
                  fontSize: '16px',
                  resize: 'none',
                  padding: '10px',
                  marginBottom: '25px',
                  maxWidth: '290px',
                }}
              />
              <Button disabled={!value} onClick={this.onSubmit}>
                SUBMIT FEEDBACK
              </Button>

              {shouldAskForFeedback && (
                <Fragment>
                  <Prompt>-- or --</Prompt>
                  <Button id="public-review" onClick={this.onPublicReview}>
                    LEAVE PUBLIC REVIEW
                  </Button>
                </Fragment>
              )}
            </Screen>
          );
        }}
      </ReviewConfigContext.Consumer>
    );
  }
}
/* eslint-enable no-undef */
