import { route } from 'preact-router';
import axios from 'axios';

import { Button, Prompt, Screen } from './components';
import { ReviewConfigContext } from './ReviewConfigContext';

const submitRating = (reviewFeedbackId, rating) =>
  axios.post(`/review/status/${reviewFeedbackId}`, {
    status: 'rated',
    rating,
  });

const unhappy = (reviewId, reviewFeedbackId, config) => () => {
  submitRating(reviewFeedbackId, 1).then(() =>
    route(`/${reviewId}/feedback/1`),
  );
};

const neutral = (reviewId, reviewFeedbackId, config) => () => {
  submitRating(reviewFeedbackId, 2).then(() =>
    route(`/${reviewFeedbackId}/feedback/2`),
  );
};

const happy = (reviewId, reviewFeedbackId, config) => () => {
  submitRating(reviewFeedbackId, 3).then(() =>
    route(`${reviewFeedbackId}/feedback/3`),
  );
};

const veryHappy = (reviewId, reviewFeedbackId, config) => () => {
  submitRating(reviewFeedbackId, 4).then(() =>
    route(`${reviewFeedbackId}/feedback/4`),
  );
};
export default props => {
  const { reviewId, reviewFeedbackId } = props;
  return (
    <ReviewConfigContext.Consumer>
      {({ config }) => (
        <Screen id="rating">
          <Prompt>How happy were you with your experience?</Prompt>
          <Button onClick={veryHappy(reviewId, reviewFeedbackId, config)}>
            VERY HAPPY
          </Button>
          <Button onClick={happy(reviewId, reviewFeedbackId, config)}>
            HAPPY
          </Button>
          <Button onClick={neutral(reviewId, reviewFeedbackId, config)}>
            NEUTRAL
          </Button>
          <Button onClick={unhappy(reviewId, reviewFeedbackId, config)}>
            UNHAPPY
          </Button>
        </Screen>
      )}
    </ReviewConfigContext.Consumer>
  );
};
